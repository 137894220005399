<template>
  <div :class="['p-segmented-control', { 'p-segmented-control--underlined': underlined }]">
    <div class="p-segmented-control__wrapper" :size="size">
      <template v-for="(item, index) in items" :key="item.key">
        <component
          :is="componentName(item)"
          :icon="item.icon"
          :size="size"
          :to="item.url"
          :class="[
            'p-segmented-control__item',
            { 'p-segmented-control__item--selected': selectedIndex === index },
          ]"
          @click="(e) => setModelValue(e.target, item.key, index)"
        >
          <template v-if="!item.icon">{{ item.display }}</template>
        </component>
      </template>
      <div class="p-segmented-control__selected" :style="selectedStyle" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

const route = useRoute()

interface Items {
  display: string | number
  key: string
  url?: string
}

const props = defineProps({
  items: {
    type: Array as () => Items[],
    required: true,
  },
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
  },
  selectedItem: {
    type: Number,
    default: 0,
  },
  underlined: {
    type: Boolean,
    default: false,
  },
})

const selectedIndex = ref<Number>(props.selectedItem)

const modelValue = defineModel<Number | String>('modelValue', { required: false, default: 0 })

const emit = defineEmits(['on-change'])

const selectedTarget = ref(null)

const selectedStyle = computed(() => {
  // const targetPadding = props.size == 'sm' ? 6 : props.size == 'md' ? 4 : 12
  const targetWidth = selectedTarget.value?.clientWidth
  const targetLeft = selectedTarget.value?.offsetLeft
  const width = props.underlined ? targetWidth : 100 / props.items?.length
  const left = props.underlined ? targetLeft : width * Number(selectedIndex.value)
  const symbol = props.underlined ? 'px' : '%'
  return `width:${width}${symbol}; left: ${left}${symbol}`
})

function componentName(item: Items) {
  return item.icon ? resolveComponent('p-icon') : item.url ? resolveComponent('nuxt-link') : 'div'
}

function setModelValue(target: HTMLElement, key: string, index: number) {
  selectedIndex.value = index
  modelValue.value = key
  emit('on-change')
  selectedTarget.value = target
}

function routerSelectedIndex() {
  if (props.items.find((item) => item.url)) {
    let selectedUrl = props.items.findIndex((item) => item?.url === route?.path)
    if (selectedUrl === -1) {
      selectedUrl = props.items.findIndex((item) => route?.fullPath?.includes(item?.url as string))
    }
    selectedIndex.value = selectedUrl >= 0 ? selectedUrl : 0
    setInitialSelectedTarget()
  }
}

function setInitialSelectedTarget() {
  const selected = document.querySelectorAll('.p-segmented-control__item')
  if (selected.length) {
    selectedTarget.value = selected[selectedIndex.value]
  }
}

watch(
  () => route?.fullPath,
  () => routerSelectedIndex(),
)

onMounted(() => {
  routerSelectedIndex()
})
</script>
